import {translation, device, browser} from 'helpers/utilsHelper.js';
import {startSessionTimeInterval, stopSessionTimeInterval} from 'accountActions.js';
import { clearPersonalData } from 'customerActions.js';
import {getSessionCookie, removeSessionCookie, removeAutologinCookies} from 'cookiesHelper.js';

export const USER_SIGNIN = 'USER_SIGNIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const BRANDS_DATA = 'BRANDS_DATA';


const signInUserSuccess = (user) => {
    return async(dispatch, getState) => {
        dispatch({
            type: `${USER_SIGNIN}_SUCCESS`,
            payload: { user }
        });
        if (CLIENT == 'fuksiarz' || CLIENT == 'etoto') {
          dispatch(startSessionTimeInterval());
        }
    }
};

const logOutUser = (clickedByUser = false) => async (dispatch, getState, {AuthApi}) => {
    try{
        const sessionCookie = getSessionCookie();
        AuthApi.logoutUser(sessionCookie);
    }catch(error){
        console.log('logOutUser: ', error)
    }

    dispatch({type: `${USER_LOGOUT}`});

    clickedByUser && removeAutologinCookies();

    if (app.config.fofEnabled?.fofPerCustomerEnabled) {
        localStorage.removeItem('_fof_parameters');
        app.service.BetSlip.refreshOddsForPrematchEventsOnBetSlip('isLogOutUser'); 
    }

    if (CLIENT == 'fuksiarz' || CLIENT == 'etoto') {
      dispatch(stopSessionTimeInterval());
    }

    dispatch(clearPersonalData());
    removeSessionCookie();
};

const isValueUnique = (fieldName, fieldValue) => {
    return async (dispatch, getState, { AuthApi }) => {
        try {
            let fName = (fieldName == 'loginName') ? 'login' : (fieldName == 'personalIdentificationNumber' ? 'personalIdentifier' : fieldName);
            let fValue = fieldValue;
            const { data } = await AuthApi.isValueUnique(fName, fValue);
            if (!data) {
                if (fieldName == 'personalIdentificationNumber'){
                    throw 'personalIdentificationNumber_isUsed';
                }
                throw `${fName}_isUsed`;
            }

            return data;
        } catch (error) {
            throw error;
        }
    }
};

const smsCodeVerify = (fieldName, fieldValue, mobileNumber) => {
    return async (dispatch, getState, { AuthApi }) => {
        try {
            let fName = fieldName;
            let fValue = fieldValue;
            const {code, data, description} = await AuthApi.validateSMSCode(fValue, mobileNumber, false);
            if (!data) {
                throw `${fName}_wrongSMSCode`;
            }

            return data;
        } catch (error) {
            throw error;
        }
    }
};

const smsCodeVerifyWithAttempts = (fieldName, fieldValue, mobileNumber) => {
    return async (dispatch, getState, {AuthApi}) => {
        try {
            let fName = fieldName;
            let fValue = fieldValue;
            const {code, data} = await AuthApi.validateSMSCode(fValue, mobileNumber, true);

            if (code == 440) {
                throw {message: `${fName}_wrongAttemptsLimitExceeded`, code};
            } else if (data == false) {
                throw `${fName}_wrongSMSCode`;
            }
            return data;
            
        } catch (error) {
            throw error;
        }
    }
};

const verificationCode = (mobile)=> {
    return async(dispatch, getState, {AuthApi}) =>{
        try {
            const {code, data} = await AuthApi.verificationCode(mobile);
            if (code == 200) {
                return data;
            }
            throw translation(`verificationCode_error`);
        } catch (error) {
            throw error;
        }
    }
};

const preregisterUser = (user) => {
    return async (dispatch, getState, { AuthApi }) => {
        try {
            const { code, data } = await AuthApi.preregisterUser(user);
            if (code == 200) {
                return data;
            }
        } catch (error) {
        }
    }
};

const registerUser = (user) => {
    return async(dispatch, getState, {AuthApi}) => {
        // set channel to desktop
        window.isWebView ? user['channel'] = "androidApp" : user['channel'] = "mobile";
        // check browser
        user['browser'] = browser();
        // check device
        user['device'] = device();

        try {
            const {code, data, description} = await AuthApi.registerUser(user);

            if (code == 10101) {
                throw {message: translation(`loginApiError_${code}`)};
            } else if (code != 200) {
                throw {message: translation(`loginApiError_${code}`)};
            } else {
                return data;
            }
        } catch ({message}) {
            throw message;
        }
    };
};

const registerUserByMyId = (user) => {
    return async(dispatch, getState, {AuthApi}) => {
        // set channel to desktop
        window.isWebView ? user['channel'] = "androidApp" : user['channel'] = "mobile";
        // check browser
        user['browser'] = browser();
        // check device
        user['device'] = device();

        try {
            const {code, data, description} = await AuthApi.registerUserByMyId(user);

            if (code == 10101) {
                throw {message: translation(`loginApiError_${code}`)};
            } else if (code != 200) {
                throw {message: translation(`loginApiError_${code}`)};
            } else {
                return data;
            }
        } catch ({message}) {
            throw message;
        }
    };
};

const forgotPassword = (email) => {
    return async(dispatch, getState, {AuthApi}) => {
        try {
            const {code, data, description} = await AuthApi.forgotPassword(email);
            if (code == 423 && description?.includes('customer-not-found')) {
                throw {message: translation(`resetPassword_userNotFound`)};
            } else if (code != 200) {
                throw {message: translation(`resetPassword_error_${code}`)};
            } else {
                if (data == true) {
                    return data;
                } else {
                    throw {message: translation('resetPassword_error')}
                }
            }
        } catch ({message}) {
            throw message;
        }
    }
};

const changePassword = (passwordData) => {
    return async(dispatch, getState, {AuthApi}) => {
        try {
            const {code, data, description} = await AuthApi.changePassword(passwordData);
            if (code == 200) {
                return data;
            } else if (code == 464) {
                throw {message: translation(`login_api_error_${description}`)};
            } else if (code == 465) {
                throw {message: translation(`api_error_465`)};
            } else {
                throw {message: description};
            }
        } catch ({message}) {
            throw message;
        }
    }
};

const updateUserBalance = (data) => {
    return {
        type: UPDATE_USER_BALANCE,
        payload: {data}
    }
};

const fetchBrands = () => {
    return async(dispatch, getState, {AuthApi}) => {
  
        dispatch(fetchBrandsPending());
  
        try {
            const {data} = await AuthApi.getBrandData();
            if(!data){
                throw 'empty brands array!';
            }
  
            dispatch(fetchBrandsSuccess(data));
            return data;
  
        } catch (error) {
            dispatch(fetchBrandsFailure(error));
            throw error;
        }
    }
};
  
const fetchBrandsFailure = (error) => {
    return {
        type: `${BRANDS_DATA}_FAILURE`,
        payload: {error}
    }
};
  
const fetchBrandsSuccess = (brands) => {
    
    const brandId = app.config.brandId || app.enumerator.Brands.BRANDSMAP[app.config.mainCountryCode.toLowerCase()];
    const brandData = brands.find(x=>x.brandId == brandId);

    return {
        type: `${BRANDS_DATA}_SUCCESS`,
        payload: {brandData}
    }
};
  
const fetchBrandsPending = () => {
    return {
        type: `${BRANDS_DATA}_PENDING`
    }
};

export {
    changePassword,
    signInUserSuccess,
    logOutUser,
    isValueUnique,
    smsCodeVerify,
    preregisterUser,
    registerUser,
    registerUserByMyId,
    updateUserBalance,
    verificationCode,
    smsCodeVerifyWithAttempts,
    forgotPassword,
    fetchBrands
}
