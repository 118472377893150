import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SubmissionError, reset, change} from 'redux-form';
import store from 'store';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import { getPromoCookie, setPromoCookie } from 'cookiesHelper.js';
import { translation, redirect, pushCustomEventToGoogleAnalytics } from 'helpers/utilsHelper.js';
import { preregisterUser, registerUser, verificationCode } from 'authActions.js';
import { loadPublicRegConsents, getMyIdStatus } from 'customerActions.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js';
import RegisterStepLiner from 'RegisterStepLiner.js';
import RegisterOptions from 'RegisterOptions.js';
import RegisterWizardFormStep1 from 'RegisterWizardFormStep1.js';
import RegisterWizardFormStep2 from 'RegisterWizardFormStep2.js';
import S from 'StyledRegisterWizardForm.js';

import BackBtnArrowIcon from 'register-back-btn-icon.svg';
import CloseButtonIcon from 'register-close-btn-icon.svg';
import VerifiedIcon from 'verified-icon.svg';

const RegisterWizardForm = () => {
    const CUSTOMER_STEP_TWO_TAG = 'CUSTOMER_REGISTRATION_STEP_2'
    
    const [step, setNextStep] = useState(0);
    const [tooltipTranslation, setTooltipTranslation] = useState('account_registerForm_tooltip');
    const [errorCode, setRegisterErrorCode] = useState(0);
    const [consents, setRegConsents] = useState([]);
    
    const [showConfirmationModal, toggleConfirmationModal] = useToggle(false);
    const [showErrorModal, toggleErrorModal] = useToggle(false);
    const [showTooltipModal, toggleTooltipModal] = useToggle(false);
    
    const dispatch = useDispatch();
    
    // TEMP - COPIED FROM registerForm.component.js - TOTALBET forgot tell us about old features compatibility - need quick fix - should be REWRITE for REACT
    useEffect(() => {
        if (app.config.enableAffiliateCampaigns) {
            app.service.Affiliates.notifyCampaigns("registration-start", {});
        }

        if (app.config.enableSbbettingAffiliateSystem && !app.service.Auth.isLogged()) {
            const params = app.router.getPathParams();
            const affiliateCookie = app.service.Cookies.getAffiliateCodeCookie();

            if (params.affiliateCode) {
                app.service.CustomerApi.registerAffiliateVisit(params.affiliateCode, !affiliateCookie);
            }
        }

        if (app.config.enableAffiliateCampaigns) {
            const queryParams = app.router.getURLParams();
            const pathParams = app.router.getPathParams();
            let campaign = '';
            if(queryParams.promocode) {
                campaign = queryParams.promocode;
            } else if(queryParams.campaign) {
                campaign = queryParams.campaign;
            } else if(queryParams.utm_campaign) {
                campaign = queryParams.utm_campaign;
            } else if(pathParams.campaign) {
                campaign = pathParams.campaign;
            } else {
                campaign = translation('registration_autocompletePromoCode');
            }
            campaign && setPromoCookie(campaign);

            const { dispatch } = store;
            dispatch(change('registerWizardForm', 'promoCode', campaign));
        }
    }, []);

    const getPublicRegConsents = async () => {
        try {
            const data = await dispatch(loadPublicRegConsents(CUSTOMER_STEP_TWO_TAG));
            return data.CUSTOMER_REGISTRATION_STEP_2 || [];
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        const { dispatch } = store;
        if (step === 1) {
            setTimeout(() => {
                dispatch(change('registerWizardForm', 'password', ''))
            }, 200);
        }
    }, [step]);

    useEffect(() => {  
        getPublicRegConsents().then(data => setRegConsents(data));  
    }, [])  

    useEffect(() => {
        dispatch(getMyIdStatus())
    }, [])

    const selectTraditionalRegisterMethod = () => goToNextPage();

    const selectMyIdRegisterMethod = () => {
        window.location.href = '/rest/customer/identity-resolver/my-id/initialize';
    };

    const registerFirstStep = async (values) => {
        try {
            const pickedValues = ['email', 'mobile', 'emailConsent', 'smsConsent'];
            const registerData = _pick(values, pickedValues);
            
            if (_get(values, ['marketingConsent']) === true) {
                await dispatch(preregisterUser(registerData));
            }
            await dispatch(verificationCode( _pick(values, ['mobile'])));

            goToNextPage();
        } catch (error) {
            openErrorModal(error);
            throw new SubmissionError({ _error: error });
        }
    };

    const openErrorModal = (error) => {
        const errorCodeMessage = error.split('_');
        const errorCode = errorCodeMessage[errorCodeMessage.length - 1];
        setRegisterErrorCode(errorCode);
        toggleErrorModal();
    };

    const registerOnSubmit = async (postData) => {
        try {
            const newUser = await dispatch(registerUser(postData));
            app.component.LoginForm.loginAfterRegister(postData);
            return newUser;
        } catch (error) {
            throw error;
        }
    };

    const openTooltip = (fieldName = '') => {
        const tooltipTranslation = `account_registerForm_${fieldName}Tooltip`;
        setTooltipTranslation(tooltipTranslation);
        toggleTooltipModal();
    };

    const registerSecondStep = async (values) => {
        try {
            let pickedValues = [
                'firstName',
                'lastName',
                'nationality',
                'password',
                'email',
                'mobile',
                'verificationCode'
            ];

            const nationality = _get(values, ['nationality']);
            if (nationality === 'PL') {
                pickedValues = [...pickedValues, 'personalIdentifier'];
            } 
            else {
                pickedValues = [...pickedValues, 'postalCode', 'city', 'street', 'passportNumber', 'dateOfBirth'];
            }

            const promoCode = _get(values, ['promoCode']);
            if (promoCode) {
                pickedValues = [...pickedValues, 'promoCode'];
            }

            let registerData = _pick(values, pickedValues);
            if ('personalIdentifier' in registerData) {
                registerData['personalIdentificationNumber'] = registerData['personalIdentifier'];
                delete registerData['personalIdentifier'];
            }

            if ('promoCode' in registerData){
                registerData['promoCode'] = registerData['promoCode'].toUpperCase();
            }

            if ('street' in registerData) {
                registerData['address'] = registerData['street'];
                delete registerData['street'];
            }

            if ('dateOfBirth' in registerData) {
                let dateOfBirth = registerData['dateOfBirth'] = registerData['dateOfBirth'].replace(/[\/]/g, '-');
            }

            // temporary disable KASOMANIA
            registerData['accountConsents'] = [
                // { "name": "LOTTERY_PARTICIPATION", "consent": values['kasomania'] },
                { "container": "LOTTERY_PARTICIPATION", "accepted": false},
                { "container": "REGULATIONS", "accepted" : true },
                { "container": "EMAIL", "accepted": `${_get(values, ['emailConsent'])}` },
                { "container": "SMS", "accepted": `${_get(values, ['smsConsent'])}` }
            ]

            const filteredConsents = consents.filter((consent) => consent.visibleForPlayer && consent.container == 'OFFERS' );
            filteredConsents && filteredConsents.map((bonus, index) => {
                if(_get(values, ['isBonusConsent'+index])) {
                    registerData['accountConsents'].push({ "container": "OFFERS", "accepted": `${_get(values, ['isBonusConsent'+index])}`, "definitionId": bonus.definitionId})
                } else {
                    registerData['accountConsents'].push({ "container": "OFFERS", "accepted": "false", "definitionId": bonus.definitionId});
                }
            })

            const customerId = await registerOnSubmit(registerData);
            if (app.config.enableAffiliateCampaigns) {
                app.service.Affiliates.notifyCampaigns("registration-end-success", {"{customerId}" : customerId});
            }

            pushCustomEventToGoogleAnalytics({ 'event':'register-success'})
            if(_tfa) _tfa.push({notify: 'event', name: 'complete_registration', id: 1711860}) //customer tracking Taboola event

            toggleConfirmationModal();

        } catch (error) {
            openErrorModal(error);
            throw new SubmissionError({ _error: error });
        }
    };

    const errorsDataLayerLettersMap = useMemo(() => {
        return {
            'email': 'e',
            'password': 'h',
            'mobile': 't',
            'rodoClause': 'z',
            'firstName': 'i',
            'lastName': 'n',
            'personalIdentifier': 'p',
            'passportNumber': 'sz',
            'dateOfBirth': 'u',
            'promoCode':'r'
        }
    }, []);

    const checkErrorsForDataLayer = (synchronousErrors, formValues) => {
        let stepErrors = synchronousErrors;

        if(step === 2) {
            if (formValues['nationality'] === 'PL') {
                delete stepErrors['dateOfBirth'];
                delete stepErrors['passportNumber'];
            } else {
                delete stepErrors['personalIdentifier'];
            }
        }

        if (Object.keys(stepErrors).length) {
            const errorsLettersArray = [];
            for (let error in stepErrors) {
                if(errorsDataLayerLettersMap[error]) {
                    errorsLettersArray.push(errorsDataLayerLettersMap[error]);
                }
            }

            if (errorsLettersArray.length){
                pushCustomEventToGoogleAnalytics({ 'event': `register-${step}step-failed`, 'errors': errorsLettersArray });
            }    
        }
    };

    const goToNextPage = () => {
        setNextStep((prevStep) => prevStep + 1);
    };

    const goToPrevPage = () => {
        setNextStep((prevStep) => prevStep - 1);
    };

    const closeRegister = () => {
        dispatch(reset('registerWizardForm'));
        redirect('/');
    };

    const initialValuesStepOne = { 
        promoCode: getPromoCookie()
     };

    const initialValuesStepTwo = {
        nationality: process.env.MAIN_COUNTRY_CODE,
        promoCode: getPromoCookie()
    };

    return (
        <S.RegisterWizardForm>
            <ModalOpacity 
                isOpen={showConfirmationModal}
                toggleOpen={toggleConfirmationModal}
                title="Confirmation"
                showHeader={false}
                padding={0}
            >
                <S.ConfirmationModal>
                    <S.ConfirmationTitle dangerouslySetInnerHTML={{ __html: translation('account_registerForm_confirmationTitle') }} />
                    <S.ConfirmationIcon dangerouslySetInnerHTML={{ __html: VerifiedIcon }} />
                    <S.ConfirmationMsg dangerouslySetInnerHTML={{ __html: translation('account_registerForm_confirmationMessage') }} />
                    <S.ConfirmationCloseBtn 
                        onClick={() => {
                            toggleConfirmationModal();
                            dispatch(reset('registerWizardForm'));
                        }}
                    >
                        {translation('common_close')}
                    </S.ConfirmationCloseBtn>
                </S.ConfirmationModal>
            </ModalOpacity>

            <ModalOpacity 
                isOpen={showErrorModal}
                toggleOpen={toggleErrorModal}
                title="Error"
                showHeader={false}
                padding={0}
            >
                <S.ErrorModal>
                    <S.ErrorCloseBtn onClick={toggleErrorModal}>
                        <S.ErrorCloseIcon dangerouslySetInnerHTML={{ __html: CloseButtonIcon }} />
                    </S.ErrorCloseBtn>

                    <S.ErrorTitle dangerouslySetInnerHTML={{ __html: translation('account_registerForm_errorTitle')}} />
                    <S.ErrorMessage dangerouslySetInnerHTML={{ __html: translation(`account_registerForm_errorMessage_${errorCode}`) }} />

                    <S.ErrorConfirmBtn 
                        onClick={() => {
                            redirect('/');
                            toggleErrorModal();
                            dispatch(reset('registerWizardForm'));
                        }}
                    >
                        {translation('account_registerForm_errorMainPage')}
                    </S.ErrorConfirmBtn>
                </S.ErrorModal>
            </ModalOpacity>

            <ModalOpacity 
                isOpen={showTooltipModal}
                toggleOpen={toggleTooltipModal}
                title="Tooltip"
                showHeader={false}
                padding={0}
            >
                <S.TooltipModal>
                    <S.TooltipModalMsg dangerouslySetInnerHTML={{ __html: translation(`${tooltipTranslation}`) }} />
                    <S.TooltipModalCloseBtn onClick={toggleTooltipModal}>
                        {translation('common_close')}
                    </S.TooltipModalCloseBtn>
                </S.TooltipModal>
            </ModalOpacity>

            <S.Header>
                {(step === 0 &&
                    <S.RegisterWizardFormTitle>{translation('account_registerForm_chooseMethod')}</S.RegisterWizardFormTitle>
                )}

                {((step === 1 || step === 2) &&
                    <>
                        <S.HeaderButton className="backButton" onClick={goToPrevPage}>
                            <S.HeaderButtonIcon dangerouslySetInnerHTML={{ __html: BackBtnArrowIcon }}></S.HeaderButtonIcon>
                        </S.HeaderButton>
                        <S.RegisterWizardFormTitle>{translation('account_registerForm_title')}</S.RegisterWizardFormTitle>
                    </>
                )}

                <S.HeaderButton className="closeButton" onClick={closeRegister}>
                    <S.HeaderButtonIcon dangerouslySetInnerHTML={{ __html: CloseButtonIcon}}></S.HeaderButtonIcon>
                </S.HeaderButton>
            </S.Header>

            {step === 0 && (
                <RegisterOptions
                    selectTraditionalRegisterMethod={selectTraditionalRegisterMethod}
                    selectMyIdRegisterMethod ={selectMyIdRegisterMethod}
                />
            )}       

            {(step === 1 || step === 2) && (
                <RegisterStepLiner step={step} />
            )}
            
            {step === 1 && (
                <RegisterWizardFormStep1
                    onSubmit={registerFirstStep}
                    initialValues={initialValuesStepOne}
                    openTooltip={openTooltip}
                    checkErrorsForDataLayer={checkErrorsForDataLayer}
                />
            )}

            {step === 2 && (
                <RegisterWizardFormStep2
                    initialValues={initialValuesStepTwo}
                    goToPrevPage={goToPrevPage}
                    onSubmit={registerSecondStep}
                    openTooltip={openTooltip}
                    checkErrorsForDataLayer={checkErrorsForDataLayer}
                    containers={consents}
                />
            )}
        </S.RegisterWizardForm>
    )
};

export default RegisterWizardForm;